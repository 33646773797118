import React, { useEffect, useState } from "react"
import { Div, Button, Text } from "atomize"
import { Helmet } from "react-helmet"
import Layout from "../components/common/Layout"
import useReviews from "../hooks/useReviews"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import ReviewListItemWithProduct from "../components/reviews/ReviewListItemWithProduct"
import axios from "axios"
import RatingStar from "../components/reviews/RatingStar"

const sortTypes = [
  {
    id: "most-recent",
    title: "Most Recent",
    sort: "created_at",
    order: "desc",
  },
  {
    id: "oldest",
    title: "Oldest",
    field: "created_at",
    order: "asc",
  },
  {
    id: "higest-rated",
    title: "Highest Rated",
    field: "rating",
    order: "desc",
  },
  {
    id: "lowest-rated",
    title: "Lowest Rated",
    field: "rating",
    order: "asc",
  },
]

const Reviews = ({ data, perPage = 12 }) => {
  const site = (data || {}).site
  const [after, setAfter] = useState(null)
  const [before, setBefore] = useState(null)
  const [selectedSort, setSelectedSort] = useState(sortTypes[0])
  const [productOverview, setProductOverview] = useState({})
  const [page, setPage] = useState(1)
  const { data: reviewsData, loading, error, meta } = useReviews({
    productId: "fetch-all",
    after,
    before,
    sort: selectedSort.field,
    order: selectedSort.order,
    perPage,
  })

  const fetchProductOverview = async () => {
    let response = await axios.get(
      " https://api.juniphq.com/v1/product_overviews",
      {
        headers: {
          "Junip-Store-Key": "ip5LCVzAChK33V3aQZ1QFt3U",
        },
      }
    )

    setProductOverview(response.data.product_overviews[0])
  }

  useEffect(() => {
    fetchProductOverview()
  }, [])

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  console.log('productOverview ', productOverview)
  return (
    <Layout site={site}>
      <Helmet>
        <title>Not Pot Reviews &amp; Ratings</title>
        <meta name="description" content="Read additional customer reviews" />
      </Helmet>
      <Div className="bgblocks" data-bg="#EAF1F9" m={{ t: "2.375rem" }}>
        <WebsiteContainer>
          <Div textAlign="center" d="flex" flexDir="column" align="center">
            <div className="reviews-wrapper">
              <Text fontFamily="secondary" textWeight="bold" textSize="display1">
                {productOverview.rating_average?.toFixed(2)}
              </Text>

              {productOverview.rating_average ?
                <RatingStar
                  m={{ y: "1.25rem" }}
                  //rating={productOverview.rating_average?.toFixed(2)}
                  rating={
                    productOverview?.rating_average === null
                      ? 0
                      : productOverview?.rating_average?.toFixed(2)
                  }
                  width="20px"
                  height="20px"
                />
                : ''
              }
            </div>
            <Text textSize="paragraph1">
              {productOverview.rating_count} Reviews
            </Text>
            <Div
              d="flex"
              flexWrap="wrap"
              justify="center"
              maxW="20rem"
              m={{ t: "1.25rem", b: "3.125rem", x: "auto" }}
            >
              {sortTypes.map((sortType, id) => {
                return (
                  <Div key={sortType.id}>
                    <Button
                      rounded="20px"
                      shadow={selectedSort.id === sortType.id ? "2" : ""}
                      textColor={
                        selectedSort.id === sortType.id ? "white" : "blue"
                      }
                      bg={
                        selectedSort.id === sortType.id ? "blue" : "transparent"
                      }
                      onClick={() => setSelectedSort(sortType)}
                      border="1px solid"
                      borderColor="blue"
                      m={{
                        b: "1.25rem",
                        r: id % 2 === 0 ? "10px" : "0",
                      }}
                      p="10px 1.25rem"
                    >
                      {sortType.title}
                    </Button>
                  </Div>
                )
              })}
            </Div>

            {/* /*  PDP Reviews  * */}
            {/* {loading && <Loader />} */}
            <Div
              p={{ t: "2rem" }}
              w="100%"
              border={{ t: "1px solid" }}
              borderColor="#F8D4E4"
              className="pdp-reviews"
              style={{ alignItems: "start" }}
            >
              {loading
                ? Array.from(Array(12).keys()).map(index => (
                  <Div w="100%">
                    <Div
                      p="7rem 1.25rem"
                      bg="#FFFCF0;"
                      shadow="7"
                      rounded="lg"
                      h="100%"
                    />
                  </Div>
                ))
                : (reviewsData || []).map((review, id) => {
                  return (
                    <Div key={review.id} className="slide-review" w="100%">
                      <ReviewListItemWithProduct review={review} />
                    </Div>
                  )
                })}
            </Div>

            {/* </Swiper> */}
            <Div d="flex" justify="center" align="center" m={{ y: "3.125rem" }}>
              <Button
                bg="transparent"
                border="1px solid"
                borderColor={
                  meta && meta.page.before === null ? "#8e96ac" : "blue"
                }
                rounded="20px"
                textColor={
                  meta && meta.page.before === null ? "#8e96ac" : "blue"
                }
                p="10px 1.25rem"
                onClick={() => {
                  setBefore(meta.page.before)
                  setAfter(null)
                  setPage(prev => (meta.page.before ? prev - 1 : prev))
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  })
                }}
              >
                Prev
              </Button>
              <Text m="0 1.25rem">
                Displaying Reviews&nbsp;{page || 1}&nbsp;&#8211;&nbsp;
                {Math.ceil(productOverview.rating_count / 12)}
              </Text>
              <Button
                onClick={() => {
                  setAfter(meta.page.after)
                  setBefore(null)
                  setPage(prev => (meta.page.after ? prev + 1 : prev))
                  window.scrollTo({ top: 0, behavior: "smooth" })
                }}
                bg="transparent"
                border="1px solid"
                borderColor={
                  meta && meta.page.after === null ? "#8e96ac" : "blue"
                }
                rounded="20px"
                textColor={
                  meta && meta.page.after === null ? "#8e96ac" : "blue"
                }
                p="10px 1.25rem"
              >
                Next
              </Button>
            </Div>
          </Div>
        </WebsiteContainer>
      </Div>
    </Layout>
  )
}
export default Reviews

export const query = graphql`
  query Reviews {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`
