import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import { Div, Text, Image } from "atomize"
import moment from "moment"
import ls from "local-storage"
import RatingStar from "./RatingStar"
import ShowMoreText from "react-show-more-text"
import { decodeHTML } from "../../utilities/helpers"
import { voteReview } from "../../services/reviewService"

const ReviewListItemWithProduct = ({ review: item }) => {
  const [review, setReview] = useState(item)
  const [product, setProduct] = useState({})
  const [upVotes, setUpVotes] = useState(ls("upReviews") ? ls("upReviews") : [])

  const fetchProduct = async () => {
    try {
      let response = await axios.get(
        `https://api.juniphq.com/v1/products/${item.product_id}`,
        {
          headers: {
            "Junip-Store-Key": "ip5LCVzAChK33V3aQZ1QFt3U",
          },
        }
      )

      setProduct(response.data.product)
    } catch (error) {
      console.log(error.title)
    }
  }

  const isUpvoted = reviewId => {
    // const upvotes = ls("upReviews") ? ls("upReviews") : []
    return upVotes.includes(reviewId)
  }

  const isDownvoted = reviewId => {
    const downvotes = ls("downReviews") ? ls("downReviews") : []
    return downvotes.includes(reviewId)
  }

  useEffect(() => {
    fetchProduct()
  }, [])

  return (
    <>
      <Div p="1.25rem" bg="#FFFCF0" shadow="7" rounded="lg" h="100%">
        <Div d="flex" justify="space-between" align="center">
          <Text textSize="header" textWeight="bold">
            {review.customer.first_name} {review.customer.last_name}
          </Text>
          <RatingStar rating={review.rating} height="12px" width="12px" />
        </Div>
        <Div d="flex" justify="space-between" m={{ y: "10px" }}>
          <Div d="flex" align="center">
            <Image
              src="/tick-button.svg"
              transform="scale(2)translateY(2px)"
              h="1rem"
              w="1rem"
              m={{ r: "10px" }}
            />
            <Text>{moment(review.created_at).format("MMMM Do YYYY")}</Text>
          </Div>
          <Div d="flex">
            <Div
              d="flex"
              align="center"
              m={{ r: "1rem" }}
              onClick={() => {
                if (isUpvoted(review.id)) return

                voteReview({
                  review,
                  type: "up",
                  alreadyVoted: isUpvoted(review.id),
                  setReview,
                  setUpVotes,
                })
              }}
            >
              <Div
                border="1px solid"
                borderColor="blue"
                bg={isUpvoted(review.id) ? "blue" : "yellow"}
                rounded="circle"
                h="1rem"
                w="1rem"
                d="flex"
                align="center"
                justify="center"
                m={{ r: "5px" }}
              >
                <Image
                  src={
                    isUpvoted(review.id)
                      ? "/up-arrow-white.svg"
                      : "/up-arrow.svg"
                  }
                  h="8px"
                  w="6px"
                  cursor="pointer"
                />
              </Div>

              <Text>{review.up_vote_count}</Text>
            </Div>
            <Div
              d="flex"
              align="center"
              onClick={() => {
                if (isDownvoted(review.id)) return

                voteReview({
                  review,
                  type: "down",
                  alreadyVoted: isDownvoted(review.id),
                  setReview,
                  //   setUpVotes,
                })
              }}
            >
              <Div
                border="1px solid"
                borderColor="blue"
                // bg={isDownvoted(review.id) ? "blue" : "yellow"}
                rounded="circle"
                h="1rem"
                w="1rem"
                d="flex"
                align="center"
                justify="center"
                m={{ r: "5px" }}
              >
                <Image src="/down-arrow.svg" h="8px" w="6px" cursor="pointer" />
              </Div>

              <Text>{review.down_vote_count}</Text>
            </Div>
          </Div>
        </Div>

        <Div overflow="auto" textAlign="left">
          <Text m={{ b: "10px" }} textWeight="bold">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/products/${product.remote_handle}`)}
            >
              {product.title}
            </span>
            {product.title && <>&nbsp;&#8211;&nbsp;</>}
            {review.title}
          </Text>

          <Div className="show-more-text">
            <ShowMoreText
              lines={8}
              more="Show more"
              less="Show less"
              anchorClass=""
              expanded={false}
            >
              <Text
                dangerouslySetInnerHTML={{ __html: decodeHTML(review.body) }}
              ></Text>
            </ShowMoreText>
          </Div>
        </Div>
        <Div m={{ t: "10px", b: "10px" }} textAlign="left" d="flex">
          {review.photo_urls.map(image => {
            return (
              <Div
                h="3.75rem"
                w="3.75rem"
                rounded="lg"
                bgImg={image.thumbnail}
                bgSize="cover"
                bgPos="center"
                m={{ r: "10px" }}
              />
            )
          })}
        </Div>
      </Div>
    </>
  )
}

export default ReviewListItemWithProduct
